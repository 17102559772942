import cx from 'classnames'
import { useMemo } from 'react'
import * as styles from './level-colour.module.less'

export type LevelColour =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'warning'
  | 'complete'
  | 'pending'
  | 'link'
  | 'warningOutlined'
  | 'warningLink'

export interface LevelProps {
  level?: LevelColour
}

export const useLevelColour = (level: LevelColour | undefined, clickable = false) => {
  return useMemo(
    () => (level ? cx(styles[level], { [styles[`${level}Click`]]: clickable }) : styles.primary),
    [level, clickable]
  )
}
