import React from 'react'
import cx from 'classnames'
import * as styles from './spinner.module.less'
import SpinnerSvg from 'src/images/svg-components/spinner.svg'

interface Props {
  fluid?: boolean
  size?: 'sm' | 'md' | 'lg'
}

const Spinner: React.FC<Props> = ({ fluid, size = 'lg' }) => {
  return (
    <SpinnerSvg
      data-testid="spinner"
      className={cx(fluid ? styles.fluidSpinner : styles.spinner, {
        [styles.smSpinner]: !fluid && size === 'sm',
        [styles.mdSpinner]: !fluid && size === 'md',
        [styles.lgSpinner]: !fluid && size === 'lg'
      })}
    />
  )
}

export default Spinner
