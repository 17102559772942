import wrapRoot from './src/apollo/wrap-root'
import './src/utils/i18n'
import ReactDOM from 'react-dom'
export const wrapRootElement = wrapRoot

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}
