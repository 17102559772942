import { ApolloProvider } from '@apollo/client'
import React from 'react'
import createClient from './client'
import { StyleProvider } from '@clubspark-react/clubspark-react-tools'
import { QueryParamProvider } from 'use-query-params'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'src/components/error-fallback/error-fallback'

const wrapRoot = ({ element }) => {
  const isBrowser = typeof window !== 'undefined'

  return isBrowser ? (
    <QueryParamProvider>
      <WrapRoot element={element} />
    </QueryParamProvider>
  ) : (
    <WrapRoot element={element} />
  )
}

const WrapRoot: React.FC<{ element: any }> = ({ element }) => {
  // Instantiating client in `wrapRootElement` handler ensures:
  //  - there is fresh client for each SSR page
  //  - it will be called only once in browser, when React mounts
  const client = createClient()
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <StyleProvider>
        <ApolloProvider client={client}>
          <DndProvider backend={HTML5Backend}>{element}</DndProvider>
        </ApolloProvider>
      </StyleProvider>
    </ErrorBoundary>
  )
}

export default wrapRoot
