export enum SystemRole {
  NONE = 0,
  SUPER_ADMINISTRATOR = 1,
  ADMINISTRATOR = 2,
  DASHBOARD_ADMINISTRATOR = 4,
  GATED_ACCESS_ADMINISTRATOR = 8
}

export enum VenueSystemRole {
  NONE = 0,
  SUPER_ADMINISTRATOR = 1,
  ADMINISTRATOR = 2,
  EVENTS_ADMINISTRATOR = 4,
  COACHING_ADMINISTRATOR = 8,
  BOOKING_ADMINISTRATOR = 16,
  CONTACTS_ADMINISTRATOR = 32,
  MEMBERSHIP_ADMINISTRATOR = 64,
  WEBSITE_ADMINISTRATOR = 128,
  BOOKING_SHEET_ADMINISTRATOR = 256,
  COMPETITION_ADMINISTRATOR = 512,
  FULL_ACCESS = 1024,
  SECURITY_ADMINISTRATOR = 2048,
  LEAGUE_ADMINISTRATOR = 4096,
  TOURNAMENT_DIRECTOR = 8192
}

export enum StaffScope {
  NONE = 0,
  TOURNAMENTS = 1,
  REPORTS = 2,
  STAFF = 4,
  RANKINGS = 8,
  SUPERADMIN = 16,
  ADMIN = 32,
  PLAYERS = 64,
  PLAYTRACKER = 128,
  SUSPENSIONS = 256
}