import graphql from 'graphql-tag'

export const FIND_ORGANISATIONS = graphql`
  query FindOrganisations(
    $pageArgs: PaginationArgs
    $filter: OrganisationFilterOptionsInput
    $sort: SortOrder
  ) {
    findOrganisations(filter: $filter, pageArgs: $pageArgs, sort: $sort) {
      results {
        organisationName
        organisationId
        organisationType
        externalId
        parentOrganisation {
          organisationName
          organisationId
        }
        status
      }
      total
    }
  }
`

export const FIND_SCHOOL_ORGANISATIONS = graphql`
  query FindSchoolOrganisations(
    $pageArgs: PaginationArgs
    $filter: OrganisationFilterOptionsInput
    $sort: SortOrder
  ) {
    findOrganisations(filter: $filter, pageArgs: $pageArgs, sort: $sort) {
      results {
        organisationName
        organisationId
        externalId
        claimStatus
        addresses {
          city
          state
          postalCode
        }
        contacts {
          standardFamilyName
          standardGivenName
          phoneNumber
          email
        }
      }
      total
    }
  }
`
export const GET_ORGANISATION_SLUG = graphql`
  query GetOrganisationSlug($organisationId: String!) {
    getOrganisationById(organisationId: $organisationId) {
      slug
    }
  }
`
export const INVITE_USER_TO_CLAIM_ORGANISATION = graphql`
  mutation InviteUserToClaimOrganisation(
    $emailOrExternalId: String!
    $organisationId: String!
    $targetUrl: String!
  ) {
    inviteUserToClaimOrganisation(
      emailOrExternalId: $emailOrExternalId
      organisationId: $organisationId
      targetUrl: $targetUrl
    ) {
      inviteExpiry
      inviteKey
      inviteUrl
    }
  }
`
export const UNCLAIM_ORGANISATION = graphql`
  mutation UnclaimOrganisation($organisationId: String!) {
    unclaimOrganisation(organisationId: $organisationId) {
      organisationId
    }
  }
`
