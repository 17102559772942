import ROUTES from '../utils/routes'
import { ClientConfig } from './config'

const config: ClientConfig = {
  disableModules: [
    'college',
    'memberships',
    'schools',
    'lta-memberships',
    'ita-players',
    'ita-merge-players',
    'organisations/manage-organisations'
  ],
  clientName: 'USTA',
  logoAltText: 'USTA Serve Control Center',
  logoSmallFilename: 'usta-logo-small.svg',
  footerLogoFilename: 'usta-logo-footer.svg',
  faviconFilename: 'usta-favicon_alt.png',
  rootUrlRedirectsToTry: [ROUTES.TOURNAMENTS, ROUTES.PLAYERS, ROUTES.STAFF, ROUTES.REPORTS],
  siteTitle: 'USTA Tennis',
  siteDescription: 'USTA Global Admin Dashboard - Tournament Sanctioning',
  footerCopyright: 'usta copyright',
  ignoreScopes: false,
  headTaxConfigurable: true,
  safeplay: true,
  datePickerDateFormat: 'MMM d, yyyy',
  tournamentOrgMembership: true,
  noRootProvider: true,
  ageCategories: [
    { label: 'Open', value: 'OPEN', subType: 'ADULT' },
    { label: '8 & Under', value: 'U8', subType: 'JUNIOR' },
    { label: '10 & Under', value: 'U10', subType: 'JUNIOR' },
    { label: '12 & Under', value: 'U12', subType: 'JUNIOR' },
    { label: '14 & Under', value: 'U14', subType: 'JUNIOR' },
    { label: '16 & Under', value: 'U16', subType: 'JUNIOR' },
    { label: '18 & Under', value: 'U18', subType: 'JUNIOR' },
    { label: '18 & Over', value: 'O18', subType: 'ADULT' },
    { label: '30 & Over', value: 'O30', subType: 'ADULT' },
    { label: '35 & Over', value: 'O35', subType: 'ADULT' },
    { label: '40 & Over', value: 'O40', subType: 'ADULT' },
    { label: '45 & Over', value: 'O45', subType: 'ADULT' },
    { label: '50 & Over', value: 'O50', subType: 'ADULT' },
    { label: '55 & Over', value: 'O55', subType: 'ADULT' },
    { label: '60 & Over', value: 'O60', subType: 'ADULT' },
    { label: '65 & Over', value: 'O65', subType: 'ADULT' },
    { label: '70 & Over', value: 'O70', subType: 'ADULT' },
    { label: '75 & Over', value: 'O75', subType: 'ADULT' },
    { label: '80 & Over', value: 'O80', subType: 'ADULT' },
    { label: '85 & Over', value: 'O85', subType: 'ADULT' },
    { label: '90 & Over', value: 'O90', subType: 'ADULT' },
    { label: '95 & Over', value: 'O95', subType: 'ADULT' },
    { label: '100 & Over', value: 'O100', subType: 'ADULT' }
  ]
}

export default config
