import { ClientConfig } from './config'

const config: ClientConfig = {
  disableModules: [
    'college',
    'memberships',
    'schools',
    'lta-memberships',
    'ita-players',
    'ita-merge-players',
    'players',
    'staff',
    'reports',
    'tournaments',
    'areas'
  ],
  clientName: 'TC',
  logoAltText: 'TC Serve Control Center',
  logoSmallFilename: 'usta-logo-small.svg',
  footerLogoFilename: 'usta-logo-footer.svg',
  faviconFilename: 'usta-favicon_alt.png',
  siteTitle: 'Tennis Canada',
  siteDescription: 'TC Global Admin Dashboard - Tournament Sanctioning',
  footerCopyright: 'usta copyright',
  ignoreScopes: false,
  headTaxConfigurable: true,
  safeplay: true,
  datePickerDateFormat: 'MMM d, yyyy',
  tournamentOrgMembership: true,
  noRootProvider: true
}

export default config
