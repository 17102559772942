import ROUTES from '../utils/routes'
import { ClientConfig } from './config'
import { PackageYear } from '../graphql-types/lta-registration/globalTypes'

const config: ClientConfig = {
  disableModules: [
    'memberships',
    'college',
    'reports',
    'staff',
    'tournaments',
    'players',
    'rankings',
    'rankings-usta',
    'schools',
    'organisations',
    'ita-players',
    'ita-merge-players',
    'areas'
  ],
  clientName: 'LTA',
  rootUrlRedirectsToTry: [ROUTES.LTA_MEMBERSHIPS],
  logoFilename: 'lta-logo.png',
  logoAltText: 'LTA Tennis',
  logoSmallFilename: 'lta-logo-white-small.png',
  faviconFilename: 'lta-logo-white-small.png',
  siteTitle: 'LTA Tennis',
  siteDescription: 'LTA Global Admin Dashboard',
  footerCopyright: 'lta copyright',
  ignoreScopes: true,
  hideFacilitySelect: true,
  membershipYearOptions: [
    {
      value: PackageYear.YEAR_2023_2024,
      label: '2023/2024'
    },
    {
      value: PackageYear.YEAR_2022_2023,
      label: '2022/2023'
    },
    {
      value: PackageYear.YEAR_2021_2022,
      label: '2021/2022'
    },
    {
      value: PackageYear.YEAR_2020_2021,
      label: '2020/2021'
    },
    {
      value: PackageYear.YEAR_2019_2020,
      label: '2019/2020'
    }
  ]
}

export default config
