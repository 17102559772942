import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Button from '../button/button'
import { Body } from '../typography/typography'
import { navigate } from 'gatsby'
import * as styles from './error-fallback.module.less'
import Icon from '../icon/icon'

export function ErrorFallback() {
  const { t } = useTranslation()

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      direction="column"
      className={styles.errorContainer}
    >
      <Icon name="lg-empty-state" spacing={{ margins: { sm: 'bottom' } }} />
      <Body size="xxl" color={Body.color.SEMI_DARK_GREY}>
        {t('something went wrong')}
      </Body>
      <Button spacing={{ margins: { sm: 'top' } }} onClick={() => navigate('/')}>
        {t('go back')}
      </Button>
    </Grid>
  )
}
