import ROUTES from '../utils/routes'
import { ClientConfig } from './config'

const config: ClientConfig = {
  disableModules: [
    'college',
    'memberships',
    'schools',
    'lta-memberships',
    'organisations',
    'ita-players',
    'ita-merge-players',
    'areas',
    'players'
  ],
  clientName: 'STA',
  logoAltText: 'STA Control Center',
  logoSmallFilename: 'cs-logo-small.svg',
  footerLogoFilename: 'cs-logo-footer.png',
  faviconFilename: 'cs-favicon.png',
  rootUrlRedirectsToTry: [ROUTES.TOURNAMENTS, ROUTES.PLAYERS, ROUTES.STAFF, ROUTES.REPORTS],
  siteTitle: 'Singapore Tennis Association',
  siteDescription: 'STA Control Centre',
  footerCopyright: 'clubspark copyright',
  ignoreScopes: false,
  headTaxConfigurable: true,
  inviteStaffViaEmailAndName: true,
  disableTournamentSettings: true,
  stripeCountry: 'SG'
}

export default config
