import graphql from 'graphql-tag'

export const LIST_MEMBERSHIP_PACKAGES = graphql`
  query listMembershipPackage($packageYear: PackageYear) {
    listMembershipPackage(packageYear: $packageYear) {
      code
      endDate
      id
      name
      renewsOn
      organisationType
      startDate
      status
      type
      packageYear
    }
  }
`

export const GET_LTA_USER = graphql`
query user($authCode: String!) {
  user(authCode: $authCode) {
    accessToken
    userName
    userID
    userEmail
    sfContactID
  }
}
`;

export const LTA_LOGOUT = graphql`
  query logout($authCode: String!) {
    logout(authToken: $authCode)
  }
`

export const MEMBERSHIP_PACKAGE_YEAR = graphql`
query membershipPackageYear {
  membershipPackageYear {
    packageYear
  }
}`