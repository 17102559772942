import { StaffScope } from '.'
import ROUTES from '../routes'

export interface AccessRule {
  route: string
  scope: StaffScope[]
  redirect?: string
  withDynamicId?: boolean
  requiresGlobalAdmin?: boolean
}

// Rules are checked in order of array, put more specific paths at the top.
// Not all routes need to be listed, /tournaments rule covers any url beggining with /tournaments
// unless a more specific rule precedes it.
export const ACCESS_RULES: AccessRule[] = [
  {
    route: ROUTES.ADD_STAFF,
    scope: [StaffScope.STAFF, StaffScope.SUPERADMIN],
    redirect: '/staff'
  },
  {
    route: ROUTES.STAFF,
    withDynamicId: true, // edit staff page
    scope: [StaffScope.STAFF, StaffScope.SUPERADMIN],
    redirect: '/staff'
  },
  { route: ROUTES.STAFF, scope: [StaffScope.STAFF] },
  { route: ROUTES.LEVEL_CONFIG, scope: [StaffScope.TOURNAMENTS, StaffScope.SUPERADMIN] },
  { route: ROUTES.TOURNAMENT_SETTINGS, scope: [StaffScope.TOURNAMENTS] },
  { route: ROUTES.PAYOUTS, scope: [StaffScope.TOURNAMENTS, StaffScope.SUPERADMIN] },
  { route: ROUTES.TOURNAMENTS, scope: [StaffScope.TOURNAMENTS] },
  { route: ROUTES.RANKINGS, scope: [StaffScope.RANKINGS] },
  { route: ROUTES.REPORTS, scope: [StaffScope.REPORTS] },
  { route: ROUTES.PLAYERS, scope: [StaffScope.PLAYERS] },
  { route: ROUTES.MERGE_PLAYERS, scope: [StaffScope.SUPERADMIN], requiresGlobalAdmin: true }
]
