import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import moment, { isMoment, Moment } from 'moment'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-fetch-backend'
import { getClientConfig } from 'src/config/config'

/**
 * Outputs date ranges based on shared date components e.g:
 * > 30 December 2019 - 5 January 2020
 * > 13 - 19 January 2020
 * > 27 January - 2 February 2020
 */
const smartDateRange = (dates: { start: Moment; end: Moment }) => {
  if (!dates || !dates.start || !dates.end) {
    return ''
  }
  const [s, e] = [dates.start, dates.end]

  const sameYear = s.year() === e.year()
  const sameMonth = sameYear && s.month() === e.month()

  const startFormat = sameYear ? 'MMMM D' : 'll'
  const endFormat = sameMonth ? 'D, YYYY' : 'll'
  return `${s.format(startFormat)} - ${e.format(endFormat)}`
}

const formatVal = (val: any, format?: string, lng?: string) => {
  if (format === 'smart date range') {
    return smartDateRange(val)
  } else if (isMoment(val)) {
    return val.format(format)
  } else if (val instanceof Date) {
    return moment(val).format(format)
  } else return val
}

const lng = getClientConfig().language ?? 'en-US'
const fallbackLng = getClientConfig().fallbackLanguages ?? false

i18n
  .use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng,
    fallbackLng,
    load: 'currentOnly',
    ns: ['translation', 'divisions'],
    defaultNS: 'translation',
    keySeparator: '.',
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: formatVal
    },
    react: {
      useSuspense: false
    }
  })

export default i18n
