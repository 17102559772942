import {
  ApolloClient,
  InMemoryCache,
  defaultDataIdFromObject,
  NormalizedCacheObject,
  from,
  HttpLink,
  createHttpLink,
  ApolloLink
} from '@apollo/client'
import { ErrorResponse, onError } from '@apollo/client/link/error'
import fetch from 'isomorphic-fetch'
import { getEnvConfig, getClientConfig } from 'src/config/config'
import { GetEvents_tournament_featureSettings as FeatureSettings } from 'src/graphql-types/GetEvents'
import { LevelConfig_level_configuration as LevelConfig } from 'src/graphql-types/LevelConfig'
import { RunPayoutTransactionReport_runPayoutTransactionReport as TransactionReport } from 'src/graphql-types/runPayoutTransactionReport'
import { getToken, resetSession, isCognito } from 'src/utils/auth'
import { retrieveDefaultFacility } from 'src/utils/storage/local-storage'
import { setSelectedFacility, getOrgId } from './local-state'

const resetOnAuthError = (e: ErrorResponse, client: ApolloClient<any>) => {
  const { networkError: ne } = e
  if (ne && (('statusCode' in ne && ne.statusCode === 401) || ne.message === 'Failed to fetch')) {
    console.log('user auth error, logging out')
    resetSession(client, window.location.pathname)
  }
}

const setToken = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = getToken()
    return { headers: { ...headers, authorization: token ? `ClubSpark-Auth ${token}` : null } }
  })
  return forward(operation)
})

const setRootProvider = new ApolloLink((operation, forward) => {
  if (getClientConfig().noRootProvider) return forward(operation)
  operation.setContext(({ headers = {} }) => {
    const orgId = getOrgId(microservicesClient)
    return { headers: { ...headers, 'x-clubspark-root-provider-id': orgId } }
  })
  return forward(operation)
})

const setProvider = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    if (getClientConfig().isSaaS) return forward(operation)
    const orgId = getOrgId(microservicesClient)
    return {
      headers: { ...headers, 'x-clubspark-provider-id': orgId, 'ClubSpark-Organisation-Id': orgId }
    }
  })
  return forward(operation)
})

const cache = new InMemoryCache({
  dataIdFromObject: o => {
    switch (o.__typename) {
      case 'LevelConfiguration': {
        const lc = (o as unknown) as LevelConfig
        return `${lc.__typename}:${lc.contextId}:${lc.levelId}`
      }
      case 'FeatureSettings': {
        const fs = (o as unknown) as FeatureSettings
        return `${fs.__typename}:${fs.featureId}`
      }
      // case 'Account':
      // const { __typename, } = o as
      default:
        return defaultDataIdFromObject(o)
    }
  }
})

const createClient = () => {
  if (process.env.GATSBY_CLIENT === 'LTA') {
    return new ApolloClient({
      link: from([setToken, new HttpLink({ uri: getEnvConfig().MESH_GATEWAY_GQL_URL })]),
      cache: new InMemoryCache({
        dataIdFromObject: o => {
          switch (o.__typename) {
            default:
              return defaultDataIdFromObject(o)
          }
        }
      }),
      resolvers: {}
    })
  } else if (process.env.GATSBY_CLIENT === 'ITA') {
    const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
      link: from([
        onError(r => resetOnAuthError(r, client)),
        setToken,
        setProvider,
        new HttpLink({
          uri: getEnvConfig().MESH_GATEWAY_GQL_URL,
          fetch
        })
      ]),
      cache,
      resolvers: {},
      connectToDevTools: true
    })
    const defaultFac = retrieveDefaultFacility()
    if (defaultFac) {
      setSelectedFacility(defaultFac, client)
    }
    return client
  } else {
    const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
      link: from([
        onError(r => resetOnAuthError(r, client)),
        // persistedQueryLink,
        setRootProvider,
        new HttpLink({
          uri: getEnvConfig().TOURNAMENTS_GQL_URL,
          credentials: isCognito() ? 'include' : undefined,
          fetch
        })
      ]),
      resolvers: {},
      cache,
      connectToDevTools: true
    })
    const defaultFac = retrieveDefaultFacility()
    if (defaultFac) {
      setSelectedFacility(defaultFac, client)
    }
    return client
  }
}

export const tournamentsClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: from([
    onError(r => resetOnAuthError(r, tournamentsClient)),
    setToken,
    setRootProvider,
    new HttpLink({
      uri: getEnvConfig().TOURNAMENTS_GQL_URL,
      fetch
    })
  ]),
  resolvers: {},
  cache,
  connectToDevTools: true
})
const defaultFac = retrieveDefaultFacility()
if (defaultFac) {
  setSelectedFacility(defaultFac, tournamentsClient)
}

export const socialLeaguesClient = new ApolloClient({
  link: from([
    onError(r => resetOnAuthError(r, socialLeaguesClient)),
    setToken,
    new HttpLink({
      uri: getEnvConfig().SOCIAL_LEAGUES_SWIFT_GQL_URL,
      credentials: 'include',
      fetch
    })
  ]),
  cache,
  connectToDevTools: true
})

export const microservicesClient = new ApolloClient({
  link: from([
    onError(r => resetOnAuthError(r, microservicesClient)),
    setRootProvider,
    setProvider,
    new HttpLink({
      uri: getEnvConfig().GATEWAY_GQL_URL,
      fetch,
      credentials: isCognito() ? 'include' : undefined
    })
  ]),
  cache,
  connectToDevTools: true
})

export const paymentClient = new ApolloClient({
  link: from([
    onError(r => resetOnAuthError(r, paymentClient)),
    setToken,
    new HttpLink({
      uri: getEnvConfig().PAYMENT_SWIFT_GQL_URL,
      credentials: isCognito() ? 'include' : undefined,
      fetch
    })
  ]),
  cache: new InMemoryCache({
    dataIdFromObject: o => {
      switch (o.__typename) {
        case 'Report': {
          const fs = (o as unknown) as TransactionReport
          return `${fs.__typename}:${fs.reportId}`
        }
        default:
          return defaultDataIdFromObject(o)
      }
    }
  }),
  connectToDevTools: true
})

export const meshGatewayClient = new ApolloClient({
  link: from([
    onError(r => resetOnAuthError(r, meshGatewayClient)),
    setRootProvider,
    setToken,
    setProvider,
    createHttpLink({ uri: getEnvConfig().MESH_GATEWAY_GQL_URL })
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      desk_OrgConfig: {
        keyFields: ['configType', 'organisationId']
      },
      desk_OrgConfigData: {
        keyFields: ['organisationId']
      }
    }
  }),
  connectToDevTools: true
})

export const itfGatewayClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: from([
    onError(r => resetOnAuthError(r, itfGatewayClient)),
    setToken,
    new HttpLink({
      uri: getEnvConfig().ITF_GQL_URL,
      fetch
    })
  ]),
  resolvers: {},
  cache,
  connectToDevTools: true
})

export const devRankingClient = new ApolloClient({
  link: from([
    onError(r => resetOnAuthError(r, devRankingClient)),
    setProvider,
    setToken,
    createHttpLink({ uri: 'http://localhost:4006/graphql' })
  ]),
  cache: new InMemoryCache()
})

export default createClient
