import graphql from 'graphql-tag'
import { useQuery, ApolloClient } from '@apollo/client'
import { useMemo } from 'react'
import { Venue } from 'src/hooks/use-section-and-districts'
import {
  GetOrganisationSlug,
  GetOrganisationSlugVariables
} from '../graphql-types/GetOrganisationSLug'
import { GET_ORGANISATION_SLUG } from '../components/organisations/organisations-queries'
import { meshGatewayClient } from './client'

export const GET_SELECTED_FACILITY = graphql`
  query GetSelectedFacility {
    selectedFacility @client
  }
`

export const GET_VENUES = graphql`
  query GetVenues {
    venues @client
  }
`

export const setSelectedFacility = (fac, client: ApolloClient<object>) => {
  client.writeQuery({
    query: GET_SELECTED_FACILITY,
    data: { selectedFacility: JSON.stringify(fac) }
  })
}

export const useOrgId = () => {
  const { data: facilityData } = useQuery(GET_SELECTED_FACILITY)
  return useMemo(
    () => facilityData?.selectedFacility && JSON.parse(facilityData.selectedFacility).VenueID,
    [facilityData]
  )
}

export const useOrgSlug = (orgId: string) => {
  const { data: organisation } = useQuery<GetOrganisationSlug, GetOrganisationSlugVariables>(
    GET_ORGANISATION_SLUG,
    {
      client: meshGatewayClient,
      variables: {
        organisationId: orgId
      }
    }
  )
  return useMemo(() => organisation?.getOrganisationById?.slug, [organisation])
}

export const getOrgId = (client: ApolloClient<object>) => {
  const facilityData = client.readQuery({ query: GET_SELECTED_FACILITY })
  return facilityData?.selectedFacility && JSON.parse(facilityData.selectedFacility).VenueID
}

export const useOrgName = () => {
  const { data: facilityData } = useQuery(GET_SELECTED_FACILITY)
  return useMemo(
    () => facilityData?.selectedFacility && JSON.parse(facilityData.selectedFacility).Name,
    [facilityData]
  )
}

export const setVenues = (venues: Venue[], client: ApolloClient<object>) => {
  client.writeQuery({ query: GET_VENUES, data: { venues: JSON.stringify(venues) } })
}

export const useVenues = () => {
  const { data: venuesData } = useQuery(GET_VENUES)
  return useMemo(() => venuesData?.venues && JSON.parse(venuesData?.venues), [venuesData])
}
