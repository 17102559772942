import { navigate } from 'gatsby'
import { ApolloClient } from '@apollo/client'
import {
  storeToken,
  storeUsername,
  retrieveToken,
  retrieveUsername,
  clearStorage,
  storeUserVenues,
  storeUser,
  retrieveDefaultFacility,
  storeDefaultFacility,
  retrieveUser,
  setSectionsAndDistricts,
  retrieveLTAToken
} from '../storage/local-storage'
import * as classic from '../classic-api'
import { setSelectedFacility } from 'src/apollo/local-state'
import { LTA_LOGOUT } from 'src/components/lta-memberships/lta-memberships-queries'
import { getEnvConfig, getClientConfig } from 'src/config/config'
import { StaffScope, SystemRole, VenueSystemRole } from './enums'
import {
  logout as Logout,
  logoutVariables as LogoutVariables
} from 'src/graphql-types/lta-registration/logout'

export interface LoginNavState {
  redirectUrl?: string
}

export enum LoginError {
  NONE,
  GENERIC,
  NON_ADMIN,
  NO_USER,
  INVALID_SCOPE,
  REQUIRES_COGNITO_LOGIN
}

interface AuthResponse {
  access_token?: string
  expires_in?: number
  refresh_token: string
  token_type: string
}

const isBrowser = typeof window !== 'undefined'

export const COGNITO_AUTH_URL = `${
  getEnvConfig().CLUBSPARK_CLASSIC_URL
}/Account/SignIn?returnUrl=${encodeURIComponent(`${getEnvConfig().HOSTING_URL}/login?auto=1`)}`

export const COGNITO_SIGN_OUT_URL = `${
  getEnvConfig().CLUBSPARK_CLASSIC_URL
}/Account/SignOut?returnUrl=${encodeURIComponent(`${getEnvConfig().HOSTING_URL}/login`)}`

export const LTA_SIGN_OUT_URL = `${
  getEnvConfig().CLUBSPARK_CLASSIC_URL
}/Account/SignOut?returnUrl=${encodeURIComponent(`${getEnvConfig().HOSTING_URL}/login`)}&source=ra`

export const isLTA = () => {
  return process.env.GATSBY_CLIENT === 'LTA'
}

export const isCognito = () => {
  return getEnvConfig().COGNITO === 'enabled'
}

interface CongitoLoginProps {
  client: ApolloClient<object>
  redirectUrl?: string
}

export async function fetchAndStoreToken(): Promise<void> {
  const tokenRes = await fetch(
    `${getEnvConfig().CLUBSPARK_CLASSIC_URL}/Account/Tokens?clientId=clubspark-app`,
    { credentials: 'include' }
  )
  const token = tokenRes.headers.get('x-api-token')
  if (!token) throw Error('No token')
  storeToken(token)
}

export const cognitoLogin = async ({
  client,
  redirectUrl
}: CongitoLoginProps): Promise<{ errorCode: LoginError }> => {
  const errorCode = await fetchUserInfo(client)
  if (errorCode !== LoginError.NONE) {
    clearStorage()
    return { errorCode }
  }

  try {
    await fetchAndStoreToken()
  } catch {
    return { errorCode: LoginError.GENERIC }
  }

  navigate(redirectUrl ?? '/')
  return { errorCode }
}

export const login = async (
  username: string,
  password: string,
  client: ApolloClient<object>,
  redirectUrl?: string
): Promise<{ errorCode: LoginError }> => {
  const config = getEnvConfig()
  if (!config.OAUTH_URL || !config.OAUTH_AUTH_HEADER || !config.OAUTH_SCOPE) {
    throw new Error('OAuth Environment variable is not set')
  }
  const res = await fetch(config.OAUTH_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: config.OAUTH_AUTH_HEADER
    },
    body: JSON.stringify({
      username,
      password,
      scope: config.OAUTH_SCOPE,
      grant_type: 'password'
    })
  })
  const auth: AuthResponse = res && res.ok && (await res.json())
  if (auth && auth.access_token) {
    storeToken(auth.access_token)

    const errorCode = await fetchUserInfo(client)
    if (errorCode !== LoginError.NONE) {
      clearStorage()
      return { errorCode }
    }
    navigate(redirectUrl ?? '/')
    return { errorCode }
  } else {
    console.log('Failed login response', auth)
    return { errorCode: LoginError.GENERIC }
  }
}

export const forgotPassword = async (email: string): Promise<{ errorCode: LoginError }> => {
  try {
    const res = await classic.forgotPassword(email)
    if (res.ok) return { errorCode: LoginError.NONE }
    if (res.status === 400) return { errorCode: LoginError.NO_USER }
  } catch (e) {
    console.log('Error requesting password change', e)
  }
  return { errorCode: LoginError.GENERIC }
}

const fetchCurrentUser = async (): Promise<LoginError> => {
  try {
    const res = await classic.fetchCurrentUser()

    if (res.status === 401) {
      return LoginError.REQUIRES_COGNITO_LOGIN
    }
    const user = await res.json()

    if (!user) {
      return LoginError.NO_USER
    }
    storeUser(user)
    if (user?.FirstName && user?.LastName) {
      storeUsername(`${user.FirstName} ${user.LastName}`)
    }
    return LoginError.NONE
  } catch (e) {
    console.log('fetch user error', e)
    return LoginError.GENERIC
  }
}

const fetchUserInfo = async (client: ApolloClient<object>): Promise<LoginError> => {
  const isSaaS = getClientConfig().isSaaS
  const [userError, venuesError] = await Promise.all([
    fetchCurrentUser(),
    ...(!isSaaS ? [fetchUserVenues(client)] : [])
  ])
  if (userError !== LoginError.NONE) return userError

  if (venuesError !== LoginError.NONE && !isSaaS) {
    // Here we check for the global system admin role of super admin/admin who can access any venue
    if (
      (venuesError === LoginError.INVALID_SCOPE || venuesError === LoginError.NON_ADMIN) &&
      userIsGlobalAdmin()
    ) {
      // There must be a selected facility. For global admin that is not a venue admin default to National
      const national = await classic.fetchNationalVenue()
      if (national) {
        const facility = { Name: national.Name, VenueID: national.ID }
        setSelectedFacility(facility, client)
        storeDefaultFacility(facility)
        return LoginError.NONE
      }
      return LoginError.GENERIC
    }
    return venuesError
  }

  return LoginError.NONE
}

const fetchUserVenues = async (client: ApolloClient<object>): Promise<LoginError> => {
  try {
    const res = await classic.fetchUserVenues()

    if (res.status === 401) {
      return LoginError.REQUIRES_COGNITO_LOGIN
    }
    const venues: any[] | undefined = (await res.json())?.Venues

    setSectionsAndDistricts()

    // In order to log in a user must meet the following criteria:
    //  - Have a valid module scope (e.g. tournaments/staff/ranking) for a venue AND either
    //     - have an admin or superadmin scope at the venue OR
    //     - have an admin or superadmin venue system role at the venue (for backwards compat)

    let hasValidVenueWithNoScopes = false
    const adminVenues = venues?.filter?.(v => {
      if (getClientConfig().ignoreScopes) {
        const role = v.VenueSystemRoles ?? VenueSystemRole.NONE
        return role & VenueSystemRole.ADMINISTRATOR || role & VenueSystemRole.SUPER_ADMINISTRATOR
      }

      const scope: StaffScope = v.Scope ?? StaffScope.NONE

      // The new way, all in scopes
      if (scope & StaffScope.SUPERADMIN || scope & StaffScope.ADMIN) {
        // you need admin/superadmin AND a module scope
        const hasModuleScope =
          scope & StaffScope.TOURNAMENTS ||
          scope & StaffScope.RANKINGS ||
          scope & StaffScope.REPORTS ||
          scope & StaffScope.STAFF ||
          scope & StaffScope.PLAYERS ||
          scope & StaffScope.PLAYTRACKER ||
          scope & StaffScope.SUSPENSIONS ||
          getClientConfig().ignoreScopes

        if (!hasModuleScope) hasValidVenueWithNoScopes = true
        return hasModuleScope
      }
      return false
    })

    if (!adminVenues?.length) {
      return hasValidVenueWithNoScopes ? LoginError.INVALID_SCOPE : LoginError.NON_ADMIN
    } else {
      storeUserVenues(adminVenues)

      // Ensure the logged in user is an admin at the browser-stored default facility
      const currentDefaultId = retrieveDefaultFacility()?.VenueID
      const saveDefault = adminVenues.find(v => v.VenueID === currentDefaultId) ?? adminVenues[0]
      storeDefaultFacility(saveDefault) // may have updated
      setSelectedFacility(saveDefault, client)
    }
    return LoginError.NONE
  } catch (e) {
    console.log('fetch user venues error', e)
    return LoginError.GENERIC
  }
}

export const getToken = () => {
  return retrieveToken()
}

export const getUsername = () => {
  const username = retrieveUsername()
  return username || 'Admin User'
}

// For the LTA we need to first invalidate the LTA SSO token
export const ltaLogout = (client?: ApolloClient<any>) => {
  const authCode = retrieveLTAToken() || ''

  client
    ?.query<Logout, LogoutVariables>({ query: LTA_LOGOUT, variables: { authCode } })
    .finally(() => {
      clearStorage()

      client?.clearStore()

      window.location.href = LTA_SIGN_OUT_URL
    })
}

export const logOut = (client?: ApolloClient<any>) => {
  if (!isBrowser) return

  if (isLTA()) {
    ltaLogout(client)
  } else if (isCognito()) {
    clearStorage()
    client?.clearStore()
    window.location.href = COGNITO_SIGN_OUT_URL
  } else {
    resetSession(client)
  }
}

export const resetSession = (client?: ApolloClient<any>, redirectUrl?: string) => {
  if (isBrowser) {
    const state: LoginNavState = { redirectUrl }
    clearStorage()
    client?.clearStore()
    navigate('/login', { state })
  }
}

export const userIsGlobalAdmin = () => {
  const userSystemRoles = retrieveUser()?.SystemRoles ?? SystemRole.NONE
  return !!(
    userSystemRoles & SystemRole.SUPER_ADMINISTRATOR || userSystemRoles & SystemRole.ADMINISTRATOR
  )
}
