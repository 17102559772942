// extracted by mini-css-extract-plugin
export var bodyBase = "level-colour-module--body-base--HPGn8 level-colour-module--site-font--pK2wC";
export var bodyLarge = "level-colour-module--body-large--SoIJa level-colour-module--body-base--HPGn8 level-colour-module--site-font--pK2wC";
export var bodyLargeBold = "level-colour-module--body-large-bold--GLkQi level-colour-module--body-base--HPGn8 level-colour-module--site-font--pK2wC";
export var bodyRegular = "level-colour-module--body-regular--VHuep level-colour-module--body-base--HPGn8 level-colour-module--site-font--pK2wC";
export var bodyRegularBold = "level-colour-module--body-regular-bold--PZCKb level-colour-module--body-base--HPGn8 level-colour-module--site-font--pK2wC";
export var bodySmall = "level-colour-module--body-small--B9Rm3 level-colour-module--body-base--HPGn8 level-colour-module--site-font--pK2wC";
export var bodySmallBold = "level-colour-module--body-small-bold--rN--R level-colour-module--body-base--HPGn8 level-colour-module--site-font--pK2wC";
export var borderTop = "level-colour-module--border-top--CR5j9";
export var breakWordContainer = "level-colour-module--break-word-container--k3rKO";
export var buttonIconBase = "level-colour-module--button-icon-base--QcacW";
export var clickLink = "level-colour-module--click-link--RzyU0";
export var complete = "level-colour-module--complete--tLaF+ level-colour-module--text-inverted--ueqtI";
export var completeClick = "level-colour-module--complete-click--fJiAj";
export var dropdownBase = "level-colour-module--dropdown-base--IYOfZ";
export var dropdownSelectBase = "level-colour-module--dropdown-select-base--T3T-A level-colour-module--text-input--+4a69";
export var flexCol = "level-colour-module--flex-col--FABxy";
export var formErrorMessage = "level-colour-module--form-error-message--Oh9YV";
export var h3 = "level-colour-module--h3--CLonY";
export var h4 = "level-colour-module--h4--nYltL";
export var hoverLink = "level-colour-module--hover-link--InZeV";
export var hoverRow = "level-colour-module--hover-row--aHcXw";
export var link = "level-colour-module--link--0Ie3h";
export var membershipContainer = "level-colour-module--membership-container--u5OXB level-colour-module--flex-col--FABxy level-colour-module--primary-border--FyIPw";
export var membershipHeader = "level-colour-module--membership-header--TrZG0";
export var membershipHeading = "level-colour-module--membership-heading--pGesr";
export var membershipLabel = "level-colour-module--membership-label--3e8Uw";
export var moreFiltersBorderClass = "level-colour-module--more-filters-border-class--8IJ-F";
export var pageBg = "level-colour-module--page-bg--dh7Wq";
export var pending = "level-colour-module--pending--0U1be level-colour-module--text-inverted--ueqtI";
export var pointer = "level-colour-module--pointer--MZ-EJ";
export var primary = "level-colour-module--primary--AkQqb level-colour-module--text-inverted--ueqtI";
export var primaryBorder = "level-colour-module--primary-border--FyIPw";
export var primaryClick = "level-colour-module--primary-click--BlNI3";
export var secondary = "level-colour-module--secondary--wOoa5";
export var secondaryClick = "level-colour-module--secondary-click--w-QZn";
export var shadowBoxLight = "level-colour-module--shadow-box-light--K3FkH";
export var siteFont = "level-colour-module--site-font--pK2wC";
export var slideDownAndFade = "level-colour-module--slideDownAndFade--WdbwE";
export var slideLeftAndFade = "level-colour-module--slideLeftAndFade--9ipuO";
export var slideRightAndFade = "level-colour-module--slideRightAndFade--b5h3T";
export var slideUpAndFade = "level-colour-module--slideUpAndFade--aruMN";
export var statusDecoration = "level-colour-module--status-decoration--Q9KW-";
export var tertiary = "level-colour-module--tertiary--MeEuh";
export var tertiaryClick = "level-colour-module--tertiary-click--7brhh";
export var textInput = "level-colour-module--text-input--+4a69";
export var textInverted = "level-colour-module--text-inverted--ueqtI";
export var textMediumDark = "level-colour-module--text-medium-dark--Sxt9q";
export var tooltipFont = "level-colour-module--tooltipFont--0WBFF";
export var unstyledButton = "level-colour-module--unstyled-button--Mu4s5";
export var warning = "level-colour-module--warning--4xT1h level-colour-module--text-inverted--ueqtI";
export var warningClick = "level-colour-module--warning-click--wuyQO";
export var warningLink = "level-colour-module--warning-link--WuhXK level-colour-module--link--0Ie3h";
export var warningOutlined = "level-colour-module--warning-outlined--3u70z level-colour-module--text-inverted--ueqtI";