import { Typography } from '@material-ui/core'
import cx from 'classnames'
import React from 'react'
import { SpacingProps, useSpacing } from 'src/hooks/spacing'
import * as styles from './typography.module.less'

enum ColorVariants {
  DEFAULT = 'defaultBodyColor',
  SEMI_DARK_GREY = 'semiDarkGrey',
  MID_GREY = 'midGrey',
  WHITE = 'white',
  ERROR = 'error'
}

interface TypographyProps extends SpacingProps {
  light?: boolean
  bold?: boolean
  p?: boolean
  color?: ColorVariants
  noMargin?: boolean
}

export interface BodyProps extends TypographyProps {
  children?: React.ReactNode
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
}

export const Body = ({
  children,
  spacing,
  light,
  size,
  bold,
  p,
  color = ColorVariants.DEFAULT
}: BodyProps) => {
  const spacingClass = useSpacing(spacing)
  const component = p ? { component: 'p' } : {}
  return (
    <Typography
      {...component}
      className={cx(
        styles.bodyComponent,
        styles[size],
        spacingClass,
        bold ? styles.bodyBold : styles.bodyNormal,
        light ? styles.bodyLight : styles.bodyDark,
        {
          [styles[color]]: color
        }
      )}
    >
      {children}
    </Typography>
  )
}

export const H1: React.FC<TypographyProps> = ({ children, spacing }) => {
  const spacingClass = useSpacing(spacing)

  return (
    <h1 className={cx(styles.h1, spacingClass ? spacingClass : styles.headingNoMargin)}>
      {children}
    </h1>
  )
}

export const H2: React.FC<TypographyProps> = ({ children, spacing, noMargin }) => {
  const spacingClass = useSpacing(spacing)
  return (
    <h2
      className={cx(styles.h2, spacingClass, {
        [styles.headingNoMargin]: noMargin
      })}
    >
      {children}
    </h2>
  )
}

export const H3: React.FC<TypographyProps> = ({ children, spacing, noMargin }) => {
  const spacingClass = useSpacing(spacing)
  return (
    <h3
      className={cx(styles.h3, spacingClass, {
        [styles.headingNoMargin]: noMargin
      })}
    >
      {children}
    </h3>
  )
}

export const H4: React.FC<TypographyProps> = ({ children, spacing, noMargin }) => {
  const spacingClass = useSpacing(spacing)
  return (
    <h4
      className={cx(styles.h4, spacingClass, {
        [styles.headingNoMargin]: noMargin
      })}
    >
      {children}
    </h4>
  )
}

export const H5: React.FC<TypographyProps> = ({ children, spacing, noMargin }) => {
  const spacingClass = useSpacing(spacing)
  return (
    <h5
      className={cx(styles.h5, spacingClass, {
        [styles.headingNoMargin]: noMargin
      })}
    >
      {children}
    </h5>
  )
}

export const H6: React.FC<TypographyProps> = ({ children, spacing, noMargin }) => {
  const spacingClass = useSpacing(spacing)
  return (
    <h6
      className={cx(styles.h6, spacingClass, {
        [styles.headingNoMargin]: noMargin
      })}
    >
      {children}
    </h6>
  )
}

// Deprecated - use <Body> directly
export const BodyLarge: React.FC<TypographyProps> = props => {
  return <Body {...props} size={'lg'} />
}

// Deprecated - use <Body> directly
export const BodyLargeBold: React.FC<TypographyProps> = props => {
  return <Body {...props} size={'lg'} bold />
}

// Deprecated - use <Body> directly
export const BodySmall: React.FC<TypographyProps> = props => {
  return <Body {...props} size={'sm'} />
}

// Deprecated - use <Body> directly
export const BodySmallBold: React.FC<TypographyProps> = props => {
  return <Body {...props} size={'sm'} bold />
}

// Deprecated - use <Body> directly
export const BodyRegular: React.FC<TypographyProps> = props => {
  return <Body {...props} size={'md'} />
}

// Deprecated - use <Body> directly
export const BodyRegularBold: React.FC<TypographyProps> = props => {
  return <Body {...props} size={'md'} bold />
}

Body.color = ColorVariants
